
import {config} from '/config.js'
export default {
    name: "Notice",
    data (){
        return{
            copyright: config.copyright,
            isShown: true
        }
    },
    methods:{
        hideNotice (){
            this.isShown = false
        }
    }

}
